import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce, snakeCase } from 'lodash'

import validationMixin from '@/mixins/validation'

import { courseMainInfoSkillsTableHead } from '@/modules/courses/helpers/main-info'

export default {
  name: 'courses-main-info-skills',
  mixins: [validationMixin],
  data () {
    return {
      valid: false,
      page: 1,
      showDialogView: false,
      showRemoveDialog: false,
      deletedItem: null,
      editedItem: null,

      keywords: '',
      payload: {
        skillId: '',
        points: ''
      },

      search: null,
      searchInput: null
    }
  },
  computed: {
    ...mapGetters({
      courseSkills: 'coursesSkills/courseSkills',
      isLoading: 'coursesSkills/isLoading',
      list: 'coursesSkills/list',
      isListLoading: 'coursesSkills/isListLoading'
    }),
    userSkills () {
      return this.info?.skills.data || []
    },
    courseMainInfoSkillsTableHead
  },
  created () {
    if (!this.list.length) this.fetchSkills({ keywords: '' })
  },
  methods: {
    ...mapActions({
      fetchSkills: 'coursesSkills/GET_LIST',
      remove: 'coursesSkills/DELETE',
      update: 'coursesSkills/UPDATE',
      create: 'coursesSkills/CREATE'
    }),
    ...mapMutations({}),
    changeInputSearch: debounce(function (val) {
      if (!val) return
      if (this.keywords !== val) {
        this.keywords = val
        this.fetchSkills({
          keywords: val,
          id: this.payload.skillId
        })
      }
    }, 500),
    autoCompleteChanged (e) {
      this.payload.skillId = e.id
      this.search = e
    },
    // end //
    changeDialogAction (item = null, status = false) {
      this.showDialogView = status
      this.editedItem = item
      if (!status) {
        this.payload.skillId = ''
        this.payload.points = ''
        this.search = null
        this.searchInput = null
      } else {
        this.payload.skillId = item.id
        this.payload.points = item.points
      }
    },

    removeItem () {
      this.remove({
        courseId: this.$route.params.course_id,
        id: this.deletedItem.id
      }).then(() => {
        this.changeDialogDelete()
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    changeDialogDelete (item = null) {
      this.showRemoveDialog = !!item
      this.deletedItem = item
    },
    createRequestPayload () {
      const formData = {}
      for (const i in this.payload) {
        formData[snakeCase(i)] = this.payload[i]
      }
      formData.courseId = this.$route.params.course_id
      if (this.editedItem) {
        formData._method = 'patch'
        formData.id = this.editedItem.id
      }
      return formData
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        if (this.editedItem) {
          this.update(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_updated'))
            this.changeDialogAction()
          })
        } else {
          this.create(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('userSuccessUpdate'))
            this.changeDialogAction()
          })
        }
      }
    }
  },
}
