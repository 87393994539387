import item from '../week-time-picker-item/index.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'week-time-picker-item',
  components: {
    item
  },
  data () {
    return {
      payload: {},
      list: [
        {
          name: 'Monday',
          day: '1',
          time: ''
        },
        {
          name: 'Tuesday',
          day: '2',
          time: ''
        },
        {
          name: 'Wednesday',
          day: '3',
          time: ''
        },
        {
          name: 'Thursday',
          day: '4',
          time: ''
        },
        {
          name: 'Friday',
          day: '5',
          time: ''
        },
        {
          name: 'Saturday',
          day: '6',
          time: ''
        },
        {
          name: 'Sunday',
          day: '7',
          time: ''
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      courseInfo: 'coursesInfo/info'
    })
  },
  created () {
    Object.entries(this.courseInfo.lessonsOpeningDays).forEach(e => {
      const currentObj = e[1]
      this.list.forEach((t, index) => {
        if (currentObj.day === t.day) {
          this.list[index].time = currentObj.time

          this.payload[currentObj.day] = currentObj.time
          this.$emit('input', this.payload)
        }
      })
    })
  },
  methods: {
    updateInput ({ day, time }) {
      this.payload[day] = time
      this.$emit('input', this.payload)
    }
  }
}
