import { mapActions, mapGetters, mapMutations } from 'vuex'
import { snakeCase } from 'lodash'
import moment from 'moment'

import mixins from '@/mixins'
import course from '@/mixins/course'
import validationMixin from '@/mixins/validation'

import MainEditor from '@/components/editor/index.vue'
import ImageFile from '@/components/image-file/index.vue'
import SlugNameInput from '@/components/slug-name-input/index.vue'
import WeekTimePicker from '../../components/week-time-picker/index.vue'

import { courseAppearanceTemplates } from '@/modules/courses/helpers/appearance'

export default {
    name        : 'courses-main-info-general',
    mixin       : [mixins, course, validationMixin],
    data () {
        return {
            showViewTemplate    : false,
            selectedTemplate    : null,
            payload             : {
                id                  : this.$route.params.hasOwnProperty('course_id') ? this.$route.params.course_id : '',
                countries           : [],
                typeId              : '',
                categoryId          : '',
                name                : '',
                diplomName          : '',
                diplomNameEn        : '',
                promoUrl            : '',
                trailerUrl          : '',
                shortDescription    : '',
                description         : '',
                batch               : '',
                statusId            : '',
                nextLessonRuleId    : '',
                image               : '',
                template            : '',
                slug                : '',
                startedAt           : '',
                // only for create fields
                countryId           : '',
                locale              : '',
                batchLocation       : '',
                nicheId             : ''
            },
            weekTimePicker      : {},
            valid               : false,
            renderWeekTimePicker: true,
            validationErrors    : {},
            locations           : [
                {
                    title   : 'Disable',
                    id      : 'disable'
                },
                {
                    title   : 'Opposite the name',
                    id      : 'opposite_the_name'
                },
                {
                    title   : 'On the picture',
                    id      : 'on_the_picture'
                }
            ],
            searchInput         : null
        }
    },
    components  : {
        MainEditor,
        ImageFile,
        WeekTimePicker,
        SlugNameInput
    },
    watch       : {
        info () {
            this.renderWeekTimePicker = false;
            
            this.$nextTick(() => {
                this.renderWeekTimePicker = true
            });
            
            this.setData()
            this.changeAvailableCountries()
        }
    },
    computed    : {
        ...mapGetters({
            setting         : 'courses/setting',
            profile         : 'profile/profile',
            categories      : 'courses/categories',
            types           : 'courses/types',
            info            : 'coursesInfo/info',
            infoLoading     : 'coursesInfo/infoLoading',
            loading         : 'coursesInfo/loading',
            accessCountries : 'profile/accessCountries',
            niches          : 'settingNiches/list'
        }),
        defaultCountries () {
            if (!this.profile || !this.info) return []
            
            const list = this.profile.countries.data.filter(e => e.type === 'access' && e.country_id !== this.info?.country?.data.country_id)
            
            return list.map(e => e.country.data)
        },
        availableCountries () {
            if (!this.profile || !this.info) return [];
            
            if (this.profile && this.info && this.$route.name !== 'courses.create') {
                const list = this.profile.countries.data.filter(e => e.type === 'access' && e.country_id !== this.info?.country?.data.country_id)
                
                return list.map(e => e.country.data)
            } else {
                const list = this.profile.countries.data.filter(e => e.type === 'access' && this.payload.countryId !== e.country_id)
                
                return list.map(e => e.country.data)
            }
        },
        locales () {
            if (this.profile) {
                const list = this.accessCountries.find(e => e.id === this.payload.countryId)
                
                return list ? list.locales.data : []
            }
            
            return []
        },
        courseAppearanceTemplates
    },
    created () {
        if (this.$route.name !== 'courses.create') {
            this.setData()
        }
        
        this.fetchNiches(true)
    },
    methods     : {
        ...mapActions({
            createCourse    : 'coursesInfo/CREATE_COURSE',
            updateInfo      : 'coursesInfo/INFO_COURSE_UPDATE',
            fetchInfo       : 'coursesInfo/INFO_GET_COURSE',
            fetchNiches     : 'settingNiches/GET_LIST'
        }),
        ...mapMutations({
            changeStepLock  : 'courses/COURSES_CHANGE_LOCK_STEP',
            resetInfo       : 'coursesInfo/INFO_SET_COURSE',
            setCountryIds   : 'profile/SET_COUNTRY_IDS'
        }),
        onView (item, e) {
            this.selectedTemplate = item;
            this.showViewTemplate = true;
            
            e.stopPropagation()
        },
        changeAvailableCountries () {
            if (this.$route.name === 'courses.create') {
                this.setCountryIds([
                    ...this.payload.countries,
                    this.payload.countryId
                ])
            } else {
                const defaultCountryId = this.info.country.data.country_id;
                
                this.setCountryIds([
                    ...this.payload.countries,
                    defaultCountryId
                ])
            }
        },
        setData () {
            Object.assign(this.payload, this.info)
        },
        formattedDate (date) {
            if (!date) return '';
            
            return moment(date).format('YYYY-MM-DD HH:mm:ss')
        },
        createRequestPayload () {
            const data = new FormData();
            
            if (this.$route.name === 'courses.create') {
                Object.entries(this.payload).forEach(e => {
                    if (e[0] !== 'id') {
                        data.append([snakeCase(e[0])], e[1])
                    }
                });
            } else {
                data.append('_method', 'patch');
                
                Object.entries(this.payload).forEach(e => {
                    if (e[0] !== 'locale' && e[0] !== 'countryId') {
                        data.append([snakeCase(e[0])], e[1]);
                    }
                })
            }
            
            if (this.payload.nextLessonRuleId === 4) {
                Object.entries(this.weekTimePicker).forEach((e, i) => {
                    if (e[1].length >= 4) {
                        data.append(`lessons_opening_days[${i}][day]`, e[0])
                        data.append(`lessons_opening_days[${i}][time]`, e[1])
                    }
                })
            }
            
            data.append('started_at', this.formattedDate(this.payload.startedAt));
            
            return data
        },
        submit () {
            this.$refs.form.validate()
            this.$refs.observer.validate();
            
            if (this.valid) {
                if (this.$route.name === 'courses.create') {
                    this.createCourse(this.createRequestPayload())
                    .then(response => {
                        this.changeStepLock(false);
                        
                        this.$router.replace({
                            name    : 'courses.info',
                            params  : {
                                course_id: response.id
                            }
                        })
                        .catch(e => console.log(e));
                        
                        this.$toasted.success(this.$t('success_create_course'))
                        this.$toasted.success(this.$t('updating_data'))
                    })
                } else {
                    this.updateInfo(this.createRequestPayload()).then(response => {
                        this.$toasted.success(this.$t('success_info_course_update'))
                        this.$toasted.success(this.$t('updating_data'))
                    })
                }
            }
        },
        autoCompleteChanged (e) {
            this.payload.nicheId = e.id
        }
    },
    beforeDestroy () {
        this.setData();
        
        if (this.$route.name === 'courses.create') {
            this.changeAvailableCountries()
        }
        
        this.changeStepLock(false)
    }
}
