import { mapGetters, mapMutations } from 'vuex'

import General from './pages/general/index.vue'
import Skills from './pages/skills/index.vue'
import Seo from './pages/seo/index.vue'

export default {
  name: 'courses-main-info',
  computed: {
    ...mapGetters({
      info: 'coursesInfo/info'
    }),
    tabs () {
      return [
        {
          disabled: false,
          name: 'General',
          key: 'general'
        },
        {
          name: 'Skills',
          key: 'skills',
          disabled: !this.info.hasOwnProperty('id')
        },
        {
          name: 'Seo',
          key: 'seo',
          disabled: !this.info.hasOwnProperty('id')
        }
      ]
    }
  },
  components: {
    General,
    Skills,
    Seo
  },
  methods: {
    ...mapMutations({
      setCourseSkills: 'coursesSkills/SET_COURSE_SKILLS'
    })
  },
  destroyed () {
    this.setCourseSkills()
  }
}
