import { mapActions, mapGetters } from 'vuex'

import SeoForm from '@/components/seo-form/index.vue'

export default {
  name: 'course-main-info-seo',
  computed: {
    ...mapGetters({
      seo: 'coursesInfo/seo',
      seoLoading: 'coursesInfo/seoLoading',
      updateSeoLoading: 'coursesInfo/updateSeoLoading'
    })
  },
  components: {
    SeoForm
  },
  created () {
    if (!this.seo) {
      this.fetchData({ course_id: this.$route.params.course_id })
    }
  },
  methods: {
    ...mapActions({
      fetchData: 'coursesInfo/INFO_GET_SEO',
      update: 'coursesInfo/INFO_UPDATE_SEO'
    }),
    submit (formData) {
      formData.course_id = this.$route.params.course_id
      this.update(formData).then(() => {
        this.$toasted.success(this.$t('success_updated'))
      })
    }
  }
}
