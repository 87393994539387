import validationMixin from '@/mixins/validation'
export default {
  name: 'week-time-picker-item',
  mixins: [validationMixin],
  props: {
    item: {}
  },
  data () {
    return {
      time: ''
    }
  },
  created () {
    if (this.item.time) {
      this.time = this.item.time
      this.handleInput()
    }
  },
  methods: {
    handleInput () {
      this.$emit('update-input', {
        day: this.item.day,
        time: this.time
      })
    }
  }
}
