export function courseMainInfoSkillsTableHead () {
  return [
    {
      text: 'ID',
      value: 'id'
    },
    {
      text: 'Points',
      value: 'points'
    },
    {
      text: 'Name',
      value: 'skill',
      sort: (a, b) => {
        const nameA = a.data.title.toLowerCase()
        const nameB = b.data.title.toLowerCase()
        if (nameA > nameB) {
          return -1
        }
        if (nameA < nameB) {
          return 1
        }
        return 0
      }
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}
